import React, { Component } from 'react';
import routes from '../src/routes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import './assets/scss/themes.scss';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            {routes.map((route, idx) => (
              <Route path={route.path} element={<route.component />} key={idx} />
            ))}
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
