import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";

// Import Logo
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

import ScrollspyNav from "./Scrollspy";

class NavbarPage extends Component {
  // render() {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          fixed={this.props.top === true ? "top" : ""}
          className={this.props.navClass + " navbar-custom sticky sticky-dark"}
          id="navbar"
        >
          <Container>
            {/* LOGO */}
            <NavbarBrand className="navbar-brand logo text-uppercase" href="/">
              {this.props.imglight === true ? (
                <img src={logolight} alt="" height="67"/>
              ) : (
                <img src={logodark} alt="" height="67"/>
              )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
              >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav className="navbar-nav ml-auto navbar-center" id="navbar-navlist">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink
                        className={item.navheading === "Home" ? "active" : ""}
                        href={"#" + item.idnm}
                      >
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

              </ScrollspyNav>

              <div className="navbar-button d-none d-lg-inline-block">
                <a href="https://cursos.seguridadallimite.com/#/student/inscription" className="btn btn-primary amarillo mr-3">Inscríbete</a>
              </div>

              <div className="navbar-button d-none d-lg-inline-block">
                <a href="https://cursos.seguridadallimite.com/#/student/certificate" className="btn btn-primary amarillo mr-3">Certificados</a>
              </div>

              <ul className="navbar-nav navbar-center">
                <li className="nav-item d-inline-block d-lg-none">
                  <a href="https://cursos.seguridadallimite.com/#/student/inscription" className="nav-link brillo" target="_blank" rel="noreferrer">Inscríbete</a>
                  <a href="https://cursos.seguridadallimite.com/#/student/certificate" className="nav-link brillo" target="_blank" rel="noreferrer">Certificados</a>
                </li>
              </ul>
              
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
// }
export default NavbarPage;