import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import Typewriter from 'typewriter-effect';

import logodark from "../../assets/images/logo-circular-dark.png";
import logolight from "../../assets/images/logo-circular-light.png";

class Section extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
 
  openModal () {
    this.setState({isOpen: true})
  }

  render() {
    return (
      <>
        <section className="bg-home home-bg-2" id="home">
            <div className="bg-overlay"></div>
            <div className="home-center">
              <div className="home-desc-center">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div className="home-content text-center">                        
                        {this.props.imglight === true ? (
                          <img src={logolight} alt="" height="200" style={{ borderRadius: "50%" }}/>
                        ) : (
                          <img src={logodark} alt="" height="200" style={{ borderRadius: "50%" }} />
                        )}
{/*
                        <HelmetProvider>
                          <div>
                            <Helmet title="Seguridad al limite - Home">
                              <meta />

                            </Helmet>
                        
                            <p>...</p>
                          </div>
                        </HelmetProvider>
    */}
                        <h1 className="mt-4 pt-3 home-title text-white">Centro de Entrenamiento para Trabajo Seguro en Alturas <span className="element text-primary" data-elements="Thamza"><Typewriter
                                options={{
                                  strings: ['SEGURIDAD AL LIMITE'],
                                  autoStart: true,
                                  loop: true,
                                }}
                              /></span></h1>

                        <p className="text-white-50 mt-4 f-20">Seguridad y salud en el trabajo <br /> y afines</p>
                        <div className="mt-4 pt-2">

                          {/* <Link to="#" className="video-play-icon text-white" onClick={this.openModal}><i className="mdi mdi-play-circle-outline text-white mr-2"></i>Mira el video de presentación</Link> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpen}
                  videoId="99025203"
                  onClose={() => this.setState({ isOpen: false })}
                />
              </div>
            </div>
        </section>
        <section className="bg-home home-bg-2-mobile" id="home">
            <div className="bg-overlay"></div>
            <div className="home-center">
              <div className="home-desc-center">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div className="home-content text-center">
                        <img src={logodark} alt="" height="200" style={{ borderRadius: "50%" }} />
                        <h1 className="mt-4 pt-3 home-title text-white">Centro de entrenamiento para trabajo Seguro en Alturas <span className="element text-primary" data-elements="Thamza"><Typewriter
                                options={{
                                  strings: ['SEGURIDAD AL LIMITE'],
                                  autoStart: true,
                                  loop: true,
                                }}
                              /></span></h1>

                        <p className="text-white-50 mt-4 f-20">Seguridad y salud en el trabajo <br /> y afines</p>
                        <div className="mt-4 pt-2">
                          <a href="https://cursos.seguridadallimite.com/#/student/inscription" className="btn btn-primary amarillo mr-3">Inscríbete</a>

                          <a href="https://cursos.seguridadallimite.com/#/student/certificate" className="btn btn-primary amarillo mr-3">Certificados</a>

                          
                          {/* <Link to="#" className="video-play-icon text-white" onClick={this.openModal}><i className="mdi mdi-play-circle-outline text-white mr-2"></i>Mira el video de presentación</Link> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <ModalVideo
                  channel="vimeo"
                  isOpen={this.state.isOpen}
                  videoId="99025203"
                  onClose={() => this.setState({ isOpen: false })}
                />
              </div>
            </div>
        </section>
      </>
    );
  }
}

export default Section;
